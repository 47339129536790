import { EnvironmentSecrets } from './types/secrets';

// export const stagingSecrets: EnvironmentSecrets = {
//   firebaseApiKey: 'AIzaSyDXkg7ZRDFqWU24dPXXyKyOVLETFEAxTPI',
//   firebaseAuthDomain: 'brain-ui-v1-staging.firebaseapp.com',
//   firebaseDatabaseURL: 'https://brain-ui-v1-staging.firebaseio.com',
//   firebaseProjectId: 'brain-ui-v1-staging',
//   firebaseStorageBucket: 'brain-ui-v1-staging.appspot.com',
//   firebaseMessagingSenderId: '103271147830',
//   firebaseAppId: '1:103271147830:web:86bd760c675fd8fb4f6b97',
//   firebaseMeasurementId: 'G-7H8X09HJ0L',
//   auth0Domain: 'staging-serviceai.us.auth0.com',
//   auth0ClientId: 'ryPYc78N9uYrQrUjsysh2cXAHgAuhdlR',
//   auth0LoginUrl: 'https://serviceai.staging.impel.io/login',
//   cloudFunctionUrl: 'us-central1-brain-ui-v1-staging.cloudfunctions.net',
// };

export const stagingSecrets: EnvironmentSecrets = {
  testFirebaseUserUserName: '',
  testFirebaseUserPassword: '',
  domain: 'staging-serviceai.us.auth0.com',
  clientId: 'ryPYc78N9uYrQrUjsysh2cXAHgAuhdlR',
  loginUrl: 'https://serviceai.staging.impel.io/login',
  cloudFunctionUrl: 'us-central1-brain-ui-v1-staging.cloudfunctions.net',
};
